<template>
  <div class="sidebar">
    <!-- <b-sidebar  visible no-header shadow no-close-on-route-change> -->
      
      <ul class="menu-list bg-gray">
        <li class="bg-primary" @click="boton (1)">
          <router-link to="/" class="bg-sidebar" exact active-class="active-menu">
          <img v-if="buttons!=1" class="ml-3 mr-2" src="@/assets/icon/icon1.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon1_w.png" > 
            Inicio
            <!-- <img class="ml-3 mr-2" src="../assets/NAO/2-home/i1.png" alt=""> Inicio -->
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (2)">
          <router-link to="/admin/NAO_Profile" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=2" class="ml-3 mr-2" src="@/assets/icon/icon2.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon2_w.png" >           
            <!-- <img  class="ml-3 mr-2" :src="buttons == 2 ? '../icon/icon2_w.png' : '../icon/icon2.png'" >  -->
             Mi Perfil
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (3)" >
          <router-link  to="/admin/NAO_Users"  class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=3" class="ml-3 mr-2" src="@/assets/icon/icon3.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon3_w.png" > 
             Usuarios
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (4)">
          <router-link to="/admin/NAO_Contact" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=4" class="ml-3 mr-2" src="@/assets/icon/icon4.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon4_w.png" > 
            Contacto
          </router-link>
        </li>
        <li v-if="rol==1 || rol==99" @click="boton (5)"> 
          <router-link to="/admin/NAO_Quote/0" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=5" class="ml-3 mr-2" src="@/assets/icon/icon5.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon5_w.png" > 
            Cotizaciones 
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (6)">
          <router-link to="/admin/NAO_NewsLetter" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=6" class="ml-3 mr-2" src="@/assets/icon/icon6.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon6_w.png" > 
            Newsletter
          </router-link>
        </li>
        
        <li v-if="rol==1" @click="boton (7)">
          <router-link to="/admin/NAO_Configuracion" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=7" class="ml-3 mr-2" src="@/assets/icon/icon7.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon7_w.png" > 
            Config. Home
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (8)">
          <router-link to="/admin/NAO_Categorias" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=8" class="ml-3 mr-2" src="@/assets/icon/icon8.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon8_w.png" >
          Categorías
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (9)">
          <router-link to="/admin/NAO_Subcategorias" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=9" class="ml-3 mr-2" src="@/assets/icon/icon9.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon9_w.png" >
          Sub Categorías
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (10)">
          <router-link to="/admin/NAO_Luxury" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=10" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" >
          Luxury Collection
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (11)">
          <router-link to="/admin/NAO_Cruceros" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=11" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" >
          Cruceros
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (12)">
          <router-link to="/admin/NAO_Circuitos" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=12" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" >
          Circuitos
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (13)">
          <router-link to="/admin/NAO_Parques" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=13" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Parques
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (14)">
          <router-link to="/admin/NAO_Blog" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=14" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Blog
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (15)">
          <router-link to="/admin/NAO_Hoteles" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=15" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Hoteles
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (16)">
          <router-link to="/admin/NAO_Deals" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=16" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Nao Deals
          </router-link>
        </li>
        <li v-if="rol==1" @click="boton (17)">
          <router-link to="/admin/NAO_Otros" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=17" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Otros servicios
          </router-link>
        </li>
        
        <li v-if="rol==1 " @click="boton (18)">
          <router-link to="/admin/NAO_Tools_admin" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=18" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Nao-tools adm.
          </router-link>
        </li>
        <li v-if="rol==99" @click="boton (19)">
          <router-link to="/admin/NAO_Tools" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=19" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Nao-tools
          </router-link>
        </li>

        <li v-if="rol==1" @click="boton (20)">
          <router-link to="/admin/NAO_Clients" class="bg-sidebar" active-class="active-menu">
          <img v-if="buttons!=20" class="ml-3 mr-2" src="@/assets/icon/icon10.png" > 
          <img v-else class="ml-3 mr-2" src="@/assets/icon/icon10_w.png" > 
                       Clientes
          </router-link>
        </li>
        <!-- <li v-if="rol==1">
          <router-link to="/admin/NAO_Promociones" class="bg-sidebar" active-class="active-menu">
            <b-icon-shop/> Promociones
          </router-link>
        </li> -->
        <!-- <li v-if="rol==1 || rol==99" class="shadow-bottom">
          <router-link to="/subcategories" class="bg-sidebar" active-class="active-menu">
            <b-icon-tags-fill/> Cerrar sesión
          </router-link>
        </li> -->
      </ul>
    <!-- </b-sidebar> -->
  
  </div>
</template>

<script>
export default {
//   watch: {
//     "$store.state.user": function (val) {
//       this.rol = val.rolId;
//     },

//   },
  // data: () => ({
  //   rol: null,
  //   buttons: 1,
    
  // }),
  
  // mounted(){
    
  //     let user = JSON.parse(localStorage.getItem('user')) || 0;
  //     this.rol = user.rol
  // },
    data() {
        return{
             rol: null,
             buttons: null,
            
            // sidebarItem:[
            //     { id: 1 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'activo',},
            //     { id: 2 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 3 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 4 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},   
            //     { id: 5 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 6 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
            //     { id: 7 ,icon: 'circle-fill',titulo: 'Vision general', estadoButton: 'inactivo',},
                           
                
                
            // ]
        }
        
    },
   async mounted(){
        this.buttons = localStorage.getItem('buton') || 1
       let user = JSON.parse(localStorage.getItem('user')) || 0;
       this.rol = user.rol
   },
    methods: {
      boton(item){
        this.buttons = item
        localStorage.setItem('button', this.buttons)
      },
    }
};
</script>

<style scoped lang="scss">
.menu-list {
  width: 100%;
  padding-left: 10px;
  min-height: 100vh;
  height: 100%;
  margin: 0;
}
.menu-list li {
  list-style-type: none;
}
.menu-list li a {
  padding: 20px 5px 20px 5px;
  // margin: 8px;
  color: rgb(0, 0, 0);
  text-decoration: none;
  display: block;
  // border-radius: 1rem 0px;
  border-width: 2px;
  border-color: black;
  border-bottom: 2px solid rgb(226, 226, 226)
}

.menu-list li svg {
  margin-right: 1rem;
  margin-left: 0.80rem;
}

.active-menu {
  font-weight: bold;
  background-color: rgb(134, 134, 134);
  color:white !important;
  // background: linear-gradient(
  //   90deg,
  //   rgba($color: #fff, $alpha: 0.2),
  //   rgba($color: #fff, $alpha: 0.25),
  //   rgba($color: #fff, $alpha: 0.2)
  // );
  box-shadow: 0px 0px 1px rgba($color: rgb(0, 0, 0), $alpha: 0.3);
}
.sidebar{
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 0px 4px 4px rgb(196, 196, 196);
}
.col-md-3{
  padding-left: 0px !important;
  padding-right: 0px !important;
}
@media only screen and (max-width: 720px) {
  .menu-list {
    width: 100%;
    padding-left: 10px;
    min-height: 53vh;
    height: 100%;
    margin: 0;
  }
}

</style>
